import React from "react";
import { Fragment } from "react/jsx-runtime";
import { ItemSupports } from "../../../enums";
import Character from "../../character/character";
import InventoryItem from "../inventory-item";
import styles from "./inventory-hero.module.css";
import { images } from "../../../constants";
import { Hero } from "../../../interfaces";

type InventorHeroProps = {
  items?: {
    name: string;
    itemCode: string;
    image: string;
    itemType?: string;
  }[];
  hero: Hero | undefined;
  onItemClick: (item: any) => void;
};

const InventoryHeroComponent = ({
  items,
  hero,
  onItemClick,
}: InventorHeroProps) => {
  const getImageByItemCode = (itemCode: string, type: string) => {
    return (images.items as any)[itemCode] || (images.itemTypes as any)[type];
  };

  const itemMemo = React.useMemo(() => {
    const el: Array<React.ReactNode> = [];
    if (!items?.length) return <div></div>;

    for (let i = 0; i < ItemSupports.length; i += 2) {
      let default1 = ItemSupports[i];
      let default2 = ItemSupports[i + 1];
      const heroItem1 = hero?.items?.find(
        (x) => x.itemType === default1.itemType
      );
      const heroItem2 = hero?.items?.find(
        (x) => x.itemType === default2.itemType
      );
      el.push(
        <Fragment key={i}>
          <InventoryItem
            onClick={(item) => {
              heroItem1 && onItemClick && onItemClick(item);
            }}
            item={{
              image: getImageByItemCode(heroItem1?.itemCode, default1.itemType),
              itemType: heroItem1?.itemType,
              isEmpty: !heroItem1?.itemCode,
            }}
          />
          <div></div>
          <InventoryItem
            onClick={(item) => {
              heroItem2 && onItemClick && onItemClick(item);
            }}
            item={{
              image: getImageByItemCode(heroItem2?.itemCode, default2.itemType),
              itemType: heroItem2?.itemType,
              isEmpty: !heroItem2?.itemCode,
            }}
          />
        </Fragment>
      );
    }
    return el;
  }, [items]);

  return (
    <>
      <div className={styles.container}>
      <div className={styles.item}>{itemMemo}</div>
        <div className={styles.character}>
          <Character />
        </div>
      </div>
    </>
  );
};

export default InventoryHeroComponent;
