import React, { useEffect } from "react";
import { images } from "../../constants";
import { usePopup } from "../../modules/popup/popup.provider";
import { useAppSelector } from "../../modules/redux/hook";
import InventoryPopupComponent from "../popup/inventory-popup/inventory-popup.component";
import Tab from "../tab";
import InventoryHeroComponent from "./inventory-hero/inventory-hero.component";
import InventoryItem from "./inventory-item";
import styles from "./inventory.module.css";

type InventoryProps = {
  items?: {
    name: string;
    itemCode: string;
    image: string;
    itemType?: string;
  }[];
};
const Inventory = ({ items }: InventoryProps) => {
  const tabs = [
    {
      name: "Equipment",
      value: "EQUIPMENT",
    },
    {
      name: "Material",
      value: "MATERIAL",
    },
    {
      name: "Other",
      value: "OTHER",
    },
  ];
  const { hero } = useAppSelector(({ app }) => app);
  const { openPopup, closePopup, isOpen } = usePopup();
  const [selectedItem, setSelectedItem] = React.useState<any>();

  useEffect(() => {
    if (!selectedItem) {
      isOpen && closePopup();
      return;
    }
    openPopup(
      <InventoryPopupComponent onClose={closePopup} onConfirm={() => {}} />
    );
  }, [selectedItem]);

  const getImageByItemCode = (itemCode: string, type: string) => {
    return (images.items as any)[itemCode] || (images.itemTypes as any)[type];
  };

  return (
    <>
      <InventoryHeroComponent
        items={items}
        hero={hero}
        onItemClick={setSelectedItem}
      />
      <Tab data={tabs} defaultValue={"EQUIPMENT"} onChanged={() => {}} />
      <div className={styles.inventoryItem}>
        {items?.map((item, index) => (
          <InventoryItem
            onClick={(item) => setSelectedItem(item)}
            item={{
              name: item.name,
              image: getImageByItemCode(item.itemCode, item.name),
              itemType: item.itemType,
            }}
            key={index}
          />
        ))}
      </div>
    </>
  );
};

export default Inventory;
