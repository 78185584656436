import { useEffect, useMemo, useState } from "react";
import { images } from "../../../constants";
import { useAppSelector } from "../../../modules/redux/hook";
import styles from "./inventory-popup.module.css";
import mainStyles from "../popup.module.css";

interface InventoryPopupProps {
  onClose: () => void;
  onConfirm: (skill: string) => void;
}
const InventoryPopupComponent = ({
  onClose,
  onConfirm,
}: InventoryPopupProps) => {
  const { appInformation, gameProfile } = useAppSelector(({ app }) => app);
  const [selectedSkill, setSelectedSkill] = useState<string>();

  useEffect(() => {
    setSelectedSkill(gameProfile?.hero?.skill);
  }, [gameProfile?.hero]);
  return (
    <div className={`${styles.container} ${mainStyles.bgPopup}`}>
      <div className={styles.controlButtonArea}>
        <button className={styles.closeButton} onClick={onClose}>
          X
        </button>
      </div>
      <div className={styles.popupContent}>
        <div className={styles.header}>
          <div className={styles.token}>
            <img src={images.coin.INGAME} alt='Token Icon' /> {3213}
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.info}>
            <h3>Vanguard</h3>
            <p>#59A9873C</p>
          </div>
          <div className={styles.image}>
            <img src={images.items.FIRE_SHIELD_L1} alt='' />
            <div className={styles.itemLevel}>
              <img src={images.coin.star} alt='' />
              <img src={images.coin.star} alt='' />
            </div>
          </div>
          <div className={styles.itemStats}>
            <p>+5% Evasion</p>
          </div>
          <div className={styles.detail}>
            <div className={styles.itemStats}>
              <p>+5% Evasion</p>
            </div>
            <div className={styles.itemStats}>
              <p>+5% Evasion</p>
            </div>
            <div className={styles.actionButton}>
              <button onClick={() => onConfirm(selectedSkill!)}>
                Re-roll stats
              </button>
            </div>
          </div>
          <div className={styles.hint}>
            <p>
              Re-roll lets players replace an item's bonus stats. The cost
              increases with each re-roll but resets to 1 TOK daily at 00:00
              UTC.
            </p>
          </div>
        </div>

        <div className={styles.bottom}>
          <div className={styles.actionButton}>
            <button onClick={() => onConfirm(selectedSkill!)}>Refill</button>
          </div>
          <div className={styles.actionButton}>
            <button onClick={() => onConfirm(selectedSkill!)}>Equip</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryPopupComponent;
