import { retrieveLaunchParams } from "@telegram-apps/sdk-react";
import { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.module.css";
import { useLoader } from "./modules/loader/loader.provider";
import { useAppDispatch, useAppSelector } from "./modules/redux/hook";
import { requestAppInformation } from "./modules/redux/slices/app.slice";
import { requestSignIn } from "./modules/redux/slices/auth.slice";
import { routes } from "./routers";

function App() {
  const dispatch = useAppDispatch();
  const { auth, app } = useAppSelector((state) => state);
  const { start, stop } = useLoader();

  useEffect(() => {
    const { initData, initDataRaw } = retrieveLaunchParams();

    if (!initData || !initDataRaw)
      throw new Error("Telegram user data not found");

    start({
      backgroundColor: "#1d2b4e",
    });
    Promise.all([
      dispatch(requestAppInformation()).unwrap(),
      dispatch(
        requestSignIn({
          provider: "TELEGRAM",
          code: String(initDataRaw!),
          referralCode: initData!.startParam,
        }),
      ).unwrap(),
    ]).then(async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      stop();
    });
  }, []);

  return (
    <>
      {app.appInformation && auth.accessToken && (
        <RouterProvider router={createBrowserRouter(routes)} />
      )}
    </>
  );
}

export default App;
