import { useContext, useEffect, useState } from "react";
import { images } from "../../constants";
import { LoaderContext } from "../../modules/loader/loader.provider";
import styles from "./loader.module.css";

export const Loader = ({ children }: { children: React.ReactNode }) => {
  const { isLoading, loaderStyle } = useContext(LoaderContext);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        setProgress((prev) => {
          if (prev < 100) return prev + 1;
          clearInterval(interval);
          return prev;
        });
      }, 30);
    }
  }, [isLoading]);

  return (
    <>
      {isLoading && (
        <div className={styles.mainContainer}>
          <div className={styles.imageContainer}>
            <div
              className={styles.grayscaleMask}
              style={{ width: `${100 - progress}%` }}
            ></div>

            <img
              src={images.loader}
              alt={"alt"}
              className={`${styles.image} ${styles.grayscale}`}
            />
            {/* <div className={styles.loadingOverlay}>{progress}%</div> */}
          </div>
        </div>
      )}
      <div
        className={styles.mainContainer}
        style={
          isLoading
            ? {
                display: "none",
              }
            : {}
        }
      >
        <>{children}</>
      </div>
    </>
  );
};

export default Loader;
