import { retrieveLaunchParams } from "@telegram-apps/sdk-react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import Loader from "./components/loader";
import PopupComponent from "./components/popup/popup.component";
import styles from "./index.module.css";
import { init } from "./init";
import { LoaderProvider } from "./modules/loader/loader.provider";
import { PopupProvider } from "./modules/popup/popup.provider";
import { ReduxProviders } from "./modules/redux/provider";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

try {
  // Configure all application dependencies.
  init(retrieveLaunchParams().startParam === "debug");
  // init(true);

  root.render(
    <>
      <ToastContainer
        limit={3}
        autoClose={1000}
        theme='dark'
        position='top-left'
        hideProgressBar={true} // Hide the progress bar
        newestOnTop={true} // Show the newest toast on top
        closeButton={false} // Hide the close button
        className={styles.toastContainer} // Custom container class
      />
      <LoaderProvider>
        <Loader>
          <div className={styles.container}>
            <PopupProvider>
              <ReduxProviders>
                <PopupComponent />
                <App />
              </ReduxProviders>
            </PopupProvider>
          </div>
        </Loader>
      </LoaderProvider>
    </>,
    // <React.StrictMode>// </React.StrictMode>
  );

  reportWebVitals();
} catch (e) {
  console.log(e);
}
