import { first, get, last } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Character from "../../components/character/character";
import Progress from "../../components/progress";
import Tab from "../../components/tab";
import { images } from "../../constants";
import { useAppDispatch, useAppSelector } from "../../modules/redux/hook";
import { removeMatchResult } from "../../modules/redux/slices/app.slice";
import styles from "./fight.module.css";

const FightPage = () => {
  const navigate = useNavigate();
  const [match, setMatch] = useState<any>(null);
  const { matchResult } = useAppSelector(({ app }) => app);
  const dispatch = useAppDispatch();
  const tabs = [
    {
      name: "Default",
      value: "DEFAULT",
    },
    {
      name: "Detailed",
      value: "DETAILED",
    },
  ];
  useEffect(() => {
    if (!matchResult) {
      toast("You are not in a match");
      navigate("/kombat");
    }
    setMatch(matchResult);

    return () => {
      dispatch(removeMatchResult());
    };
  }, []);

  const formatString = (str: string) => {
    const words = str.split(" ");
    words[0] =
      words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();
    return words.join(" ");
  };
  const { heroTop, characterMain, stats } = useMemo(() => {
    if (!match) return {};
    const {
      initData: {
        leftHeroes,
        rightHeroes,
        leftFullGameProfile,
        rightFullGameProfile,
      },
    } = match!;

    const heroes = [
      { ...leftHeroes[0], ...leftFullGameProfile },
      {
        ...rightHeroes[0],
        ...rightFullGameProfile,
      },
    ];

    const statsByHero = (
      hero: any,
      type: string | "attack" | "hp" | "evasion" | "skill",
      side: "LEFT" | "RIGHT",
      maximumSteps: number = 0
    ) => {
      if (type === "skill") {
        return {
          LEFT: (
            <div className={`${styles.statsItem} ${styles.skill}`}>
              <img
                src={get(images, ["skills", hero[type]])}
                alt={hero[type]}
                className={styles.imgSmall}
              />
              <p>
                <span>{formatString(hero[type])}</span>&nbsp;
              </p>
            </div>
          ),
          RIGHT: (
            <div className={`${styles.statsItem} ${styles.skill}`}>
              <p>
                <span>{formatString(hero[type])}</span>&nbsp;
              </p>
              <img
                src={get(images, ["skills", hero[type]])}
                alt={hero[type]}
                className={styles.imgSmall}
              />
            </div>
          ),
        }[side];
      }
      return (
        <div className={styles.statsItem}>
          <p
            style={
              side === "LEFT" ? { textAlign: "right" } : { textAlign: "left" }
            }
          >
            {side === "LEFT" ? (
              <>
                <span>{hero[type]?.point}</span>&nbsp;{formatString(type)}
              </>
            ) : (
              <>
                {formatString(type)}&nbsp;<span>{hero[type]?.point}</span>
              </>
            )}
          </p>
          <div
            className="process"
            style={
              side === "LEFT"
                ? {
                    transform: "rotateY(180deg) rotateX(180deg)",
                  }
                : {}
            }
          >
            <Progress
              total={100}
              value={((hero[type]?.point || 0) / (maximumSteps || 1)) * 100}
            />
          </div>
        </div>
      );
    };

    return {
      heroTop: (
        <>
          {heroes.map((hero, index) => {
            return (
              <div
                key={index}
                className={`${styles.hero} ${
                  index === 0 ? styles.heroLeft : styles.heroRight
                }`}
              >
                {
                  {
                    0: (
                      <>
                        <div className={styles.heroInfo}>
                          {/* TODO Hero name */}
                          <p>{hero.userId}</p>
                          <div className={styles.heroRank}>
                            <p>{hero.totalLevel}</p>
                            <img
                              src="	https://staggering.tonkombat.com/assets/champion-D65sUMQF.webp"
                              alt="Champion Icon"
                              className={styles.imgSmall}
                            />
                          </div>
                        </div>
                        <div className={styles.heroImage}>
                          <img
                            className={styles.heroImage}
                            src={images.system.user}
                            alt="Champion Icon"
                          />
                        </div>
                      </>
                    ),
                    1: (
                      <>
                        <div className={styles.heroImage}>
                          <img
                            className={styles.heroImage}
                            src={images.system.user}
                            alt="Champion Icon"
                          />
                        </div>
                        <div className={styles.heroInfo}>
                          {/* TODO Hero name */}
                          <p>{hero.userId}</p>
                          <div className={styles.heroRank}>
                            <img
                              src="	https://staggering.tonkombat.com/assets/champion-D65sUMQF.webp"
                              alt="Champion Icon"
                              className={styles.imgSmall}
                            />
                            <p>{hero.totalLevel}</p>
                          </div>
                        </div>
                      </>
                    ),
                  }[index]
                }
              </div>
            );
          })}
        </>
      ),
      characterMain: (
        <>
          <div className={styles.mainCharacters}>
            {heroes.map((hero, index) => {
              return (
                <div key={index} className={styles.character}>
                  <Character />
                </div>
              );
            })}
          </div>
        </>
      ),
      stats: (
        <>
          {[
            { code: "attack" },
            { code: "hp" },
            { code: "evasion" },
            { code: "skill" },
          ].map((item, index) => (
            <div key={index} className={styles.statsItemLine}>
              <div className={styles.statsLeft}>
                {statsByHero(
                  heroes[0],
                  item.code,
                  "LEFT",
                  Math.round(
                    Math.max(
                      get(first(heroes), [item.code, "point"], 0),
                      get(last(heroes), [item.code, "point"], 0)
                    ) / 1000
                  ) * 1000
                )}
              </div>
              <div className={styles.statsCenter}>
                <img
                  src={get(images, item.code.toLowerCase())}
                  alt={item.code}
                />
              </div>
              <div className={styles.statsRight}>
                {statsByHero(
                  heroes[1],
                  item.code,
                  "RIGHT",
                  Math.round(
                    Math.max(
                      get(first(heroes), [item.code, "point"], 0),
                      get(last(heroes), [item.code, "point"], 0)
                    ) / 1000
                  ) * 1000
                )}
              </div>
            </div>
          ))}
        </>
      ),
    };
  }, [match]);
  return (
    <div className={styles.container}>
      <div className={styles.heroTop}>{heroTop}</div>
      <div className={styles.main}>
        <div className={styles.mainAction}>
          {[
            { icon: images.info },
            {
              icon: images.system.arrow.right,
            },
          ].map((item, index) => (
            <div key={index} className={`${styles.actionContent}`}>
              <button type="button" className={styles.button}>
                <img src={item.icon} alt="arrow" />
              </button>
            </div>
          ))}
        </div>
        {characterMain}
      </div>
      <div className={styles.tabs}>
        <Tab data={tabs} defaultValue={tabs[0].value} />
      </div>
      <div className={styles.stats}>{stats}</div>
      <div className={styles.action}>
        <div className={`${styles.btn} ${styles.shareButton}`}>
          <button>
            <img src={images.system.share} alt="Share" />
            Share
          </button>
        </div>
        <div className={`${styles.btn} ${styles.nextButton}`}>
          <button onClick={() => navigate("/kombat")}>Next</button>
        </div>
      </div>
    </div>
  );
};

export default FightPage;
