export enum Attributes {
  GAME_PROFILE_LEVEL = "GAME_PROFILE_LEVEL",
  SALARY = "SALARY",
  POCKET = "POCKET",
  HONOR = "HONOR",
}

export enum Token {
  INGAME = 'INGAME',
  INGAME_2 = 'INGAME_2'
}